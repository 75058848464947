<template>
  <div class="d-flex flex-row rounded white" :class="isPinned ? 'stickyPatientInfo' : ''">
    <v-card flat width="33%" class="infoBox">
      <v-card-title class="grey lighten-4 font-weight-medium pt-1" style="height: 40px !important">
        <div><v-icon class="mr-1">mdi-tag-text-outline</v-icon> Details</div>
      </v-card-title>
      <v-card-text class="px-1" v-if="sampleInfo">
        <PatientInfo :cohortId="cohortId" :projectId="projectId" :sampleInfo="sampleInfo"></PatientInfo>
      </v-card-text>
    </v-card>
    <v-card flat width="33%" class="ml-4 infoBox" >
      <v-card-title class="grey lighten-4 font-weight-medium d-flex flex-row pt-1" style="height: 40px !important">
        <div><v-icon class="mr-1">mdi-tag-text-outline</v-icon> Disease Information</div>
      </v-card-title>
      <v-card-text class="d-flex flex-column mt-4">
        <PatientDiseaseInfo :sampleInfo="sampleInfo"></PatientDiseaseInfo>
      </v-card-text>
    </v-card>
    <v-card flat width="33%" class="ml-4 infoBox"  >
      <v-card-title class="d-flex flex-row grey lighten-4 font-weight-medium pt-1" style="height: 40px !important">
        <div>
          <div><v-icon class="mr-1">mdi-human-male-female-child</v-icon> Pedigree</div>
        </div>
        <v-icon class="ml-auto" @click="isPinned = !isPinned">{{ isPinned ? "mdi-pin-off-outline" : "mdi-pin-outline" }}</v-icon>
      </v-card-title>
      <PatientPedigree :sampleInfo="sampleInfo" :cohortId="cohortId" :projectId="projectId"></PatientPedigree>
    </v-card>
  </div>
</template>
<script>
import PatientPedigree from "@/components/Common/PatientPedigree.vue"
import PatientInfo from "@/components/Common/PatientInfo.vue";
import PatientDiseaseInfo from "@/components/Common/PatientDiseaseInfo.vue";

export default {
  name: "IndividualAnalysisPatientInfo",
  components: { PatientPedigree, PatientInfo, PatientDiseaseInfo },
  props: {
    sampleInfo: {
      type: Object,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    terms: [],
    isPinned: false,
    phenotypeMenu: false,
  }),
  methods: {},
};
</script>
<style scoped>
.stickyPatientInfo {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14) !important;
}
.infoBox {
  border: 1px #f0f0f0 solid;
}
</style>