import { render, staticRenderFns } from "./Gene.vue?vue&type=template&id=def0fea4&scoped=true"
import script from "./Gene.vue?vue&type=script&lang=js"
export * from "./Gene.vue?vue&type=script&lang=js"
import style0 from "./Gene.vue?vue&type=style&index=0&id=def0fea4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "def0fea4",
  null
  
)

export default component.exports