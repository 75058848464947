import ApiIntegrface from "../api"
const hpoApiEndpoint='https://rest.uniprot.org/uniprotkb'



//https://rest.uniprot.org/uniprotkb/search?fields=accession,reviewed,id,protein_name,gene_names,organism_name,length&query=((gene:GK3P) AND (organism_id:9606) AND (reviewed:true))
//https://rest.uniprot.org/uniprotkb/search?fields=id&query=%28%28gene%3AGK3P%29+AND+%28organism_id%3A9606%29+AND+%28reviewed%3Atrue%29%29
export function searchUniprotByGene(gene, callback){
  const api = new ApiIntegrface(hpoApiEndpoint, false)
  const params={
    fields:'id',
    query:`((gene:${gene}) AND (organism_id:9606) AND (reviewed:true))`
  }
  const url = '/search'
  api.get(url, params, callback,(err)=>{
    console.log(err)
  },null,null,false)
}