<template>
  <div>
    <v-rect v-if="type == 'square'" @mouseenter="onMouseEnter" @mouseout="onMouseOut" @mousedown="onMouseDown" @click="onClick" :config="rectConfig"></v-rect>
    <v-rect v-if="type == 'hatched'" @mouseenter="onMouseEnter" @mouseout="onMouseOut" @mousedown="onMouseDown" @click="onClick" :config="hatchedBorderConfig"></v-rect>
    <v-rect v-if="type == 'hatched'" @mouseenter="onMouseEnter" @mouseout="onMouseOut" @mousedown="onMouseDown" @click="onClick" :config="hatchedRectConfig"></v-rect>
    <v-line v-if="type == 'line'" @mouseenter="onMouseEnter" @mouseout="onMouseOut" @mousedown="onMouseDown" @click="onClick" :config="lineConfig"></v-line>
    <v-text ref="text" :config="textConfig" @mouseenter="onMouseEnter" @mouseout="onMouseOut" @click="onClick"></v-text>
  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";

Vue.use(VueKonva);
export default {
  name: "LegendItem",
  data: () => ({
    show: true,
  }),
  props: {
    fillColor: {
      type: String,
    },
    borderColor: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    },
    borderStyle: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    clickable: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onMouseDown(evt) {
      if (this.clickable) {
        evt.cancelBubble = true;
        evt.evt.preventDefault();
        evt.evt.stopPropagation();
      }
    },
    onClick() {
      if (this.clickable) {
        this.show = !this.show;
        this.$emit("onClick", this.text, this.show);
      }
    },
    onMouseEnter() {
      if (this.clickable) {
        this.$refs.text.getNode().parent.parent.container().style.cursor = "pointer";
      }
    },
    onMouseOut() {
      if (this.clickable) {
        this.$refs.text.getNode().parent.parent.container().style.cursor = "default";
      }
    },
  },
  computed: {
    textConfig() {
      return {
        x: this.x + (this.type == "line" ? 35 : 13),
        y: this.y,
        text: this.text,
        fontSize: 12,
        // fontStyle: 'bold',
        fill: "#666666",
        opacity: this.show ? 1 : 0.5,

        // align:'left',
      };
    },
    lineConfig() {
      // const end = this.toX(this.stepCount * this.stepSize)
      return {
        x: this.x,
        y: this.y + 4,
        points: [0, 0, 30, 0],
        stroke: this.borderColor,
        opacity: this.show ? 1 : 0.4,
        strokeWidth: 2,
        dash: this.borderStyle == "dashed" ? [3, 2] : null,
      };
    },
    rectConfig() {
      return {
        x: this.x,
        y: this.y,
        width: 10,
        height: 10,
        // stroke: 'black',
        // strokeWidth: this.selected? 1 : 0 ,
        // strokeWidth:  1  ,
        // fill: this.colors[this.info.type],
        fill: this.fillColor,
        opacity: this.show ? 1 : 0.5,
        // shadowBlur: this.hover? 3 : 0,
        cornerRadius: 2,
      };
    },
    hatchedBorderConfig() {
      return {
        x: this.x,
        y: this.y,
        width: 10,
        height: 10,
        stroke: "black",
        // strokeWidth: this.selected? 1 : 0 ,
        strokeWidth: 0.5,
        // fill: this.colors[this.info.type],
        fill: "white",
        opacity: this.show ? 1 : 0.5,
        // shadowBlur: this.hover? 3 : 0,
        cornerRadius: 2,
      };
    },
    hatchedRectConfig() {
      return {
        x: this.x,
        y: this.y + 3,
        width: 10,
        height: 4,
        // stroke: 'black',
        // strokeWidth: this.selected? 1 : 0 ,
        // strokeWidth:  1  ,
        // fill: this.colors[this.info.type],
        fill: "#999999",
        // shadowBlur: this.hover? 3 : 0,
        // cornerRadius: 2,
      };
    },
  },
};
</script>
