import ApiIntegrface from "./api"
import store from '@/store'

export function authenticate(username, password, callback, errHandler){
  const api = new ApiIntegrface()
  api.authIsRequired = false
  const url = "/api/v1/token/"
  const payload= { 'username' : username, 'password' : password}
  api.post(url, payload, callback, errHandler)
}

export function verifyToken(token, callback, errHandler){
  const api = new ApiIntegrface()
  api.authIsRequired = false
  const url = '/api/v1/token/verify/'
  const payload = { 'token' : token}
  api.post(url, payload, callback, errHandler, null,null,false)
}

export function refreshToken(refreshToken, callback, errHandler){
  const api = new ApiIntegrface()
  api.authIsRequired = false
  const url = '/api/v1/token/refresh/'
  const payload = { 'refresh' : refreshToken}
  api.post(url, payload, callback, errHandler, null,null,false)
}

export function deleteUser(userId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId
  api.delete(url, null, callback, null, successMessage)
}

export function addUser(payload, callback, errhandler, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users'
  api.post(url, payload, callback, errhandler, successMessage)
}



export function getUser(userId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId

  api.get(url, null, callback)
}

export function getCoworkers(callback){
  const api = new ApiIntegrface()
  const userId = + store.state.UserId 
  const url = '/api/v1/users/' + userId + '/coworkers'
  api.get(url, null, callback)

}

export function getUsers(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users'
  api.get(url, null, callback)
}

export function addOrganization(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/organizations'
  api.post(url, payload, callback, null, successMessage)
}


export function getOrganizations( callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/organizations'
  api.get(url, null, callback)
}

export function changePassword(id, oldPassword, newPassword1, newPassword2, callback,errhandler, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + id + '/set_password'
  const payload ={
    oldPassword:  oldPassword,
    newPassword1: newPassword1,
    newPassword2: newPassword2
  }
  api.post(url, payload, callback, errhandler, successMessage)
}


export function updateUser(userId, payload, callback,  errhandler, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId
  api.put(url, payload, callback, errhandler, successMessage)
}

export function getUserCohorts(userId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/cohorts'
  api.get(url, null, callback)
}

export function getUserCohortDatasets(userId, cohortId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/cohorts/' + cohortId + '/datasets'
  api.get(url, null, callback)
}


export function getUserDatasets(userId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/datasets'
  api.get(url, null, callback)
}

export function grantAccessToDatasets(userId, cohortId, payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/cohorts/'+ cohortId +'/datasets'
  api.post(url, payload, callback, null, successMessage)
}

export function revokeAccessToDatasets(userId, cohortId, payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + userId + '/cohorts/'+ cohortId +'/datasets/batch'
  api.delete(url, payload, callback, null, successMessage)
}

export function acceptTermsAndConditions(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/user/terms/accept'
  api.post(url,null,callback)
}