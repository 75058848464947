<template>
  <div style="min-height: 300px">
    <TrSnvChart ref="refrenceTad" :eventIdentifier="eventIdentifier" :type="type" :rarePathogenicTrackHintCondition="rarePathogenicTrackHintCondition"  :variantId="variantId" :compoundVariants="compoundVariants" :chromosome="chromosome" :mutations="sv" :isoforms="coolboxData.transcripts" :pedigree="pedigree" :geneEnhancers="geneEnhancers" :details="coolboxMoreDetails" :selectedUsersGenes="selectedUsersGenes"></TrSnvChart>
    <v-dialog v-model="showVariantDialog" max-width="80%" >
      <v-card min-height="700px" >
        <v-card-title>
          <div class="d-flex justify-space-between" style="width: 100%">
            <div class="text-h5">Variant Details</div>
            <div>
              <v-btn color="secondary" text @click="showVariantDialog=false"> Close </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text >
          <VariantInfo v-if="showVariantDialog" :cohortId = "cohortId" :projectId = "projectId" :variantId = "selectedVariantId"  :variantType = "selectedVariantType"  ></VariantInfo>

        </v-card-text>
      </v-card>
    </v-dialog>    
  </div>
</template>
<script>
import TrSnvChart from "@/components/defaultAnalysis/tad/TrSnvChart.vue";
import { getVariantTads } from "@/api/user/dataset";
import { getDatasetFields } from "@/api/dataset";
import { getData } from "@/api/user/dataset";
import VariantInfo from '@/components/Common/VariantInfo.vue'
// import { getDatasetFields } from "@/api/dataset";
export default {
  name: "TrSnvcoolbox",
  components: { TrSnvChart,VariantInfo },
  props: {
    cohortId: {
      type: Number,
      default: 1,
      // required: true,FF
    },
    projectId: {
      type: Number,
      required: true,
    },
    selectedDatabaseId: {
      type: Number,
      default: 1,
      // required: true,
    },
    variantId: {
      type: String,
      default: null,
    },
    compoundVariants:{
      type: Array,
      default: ()=> []
    },    
    sv: {
      type: Array,
      default: () => [],
    },
    selectedUsersGenes: {
      type: Array,
      required: true,
    },
    chromosome: {
      type: Object,
      default: () => ({
        id: null,
        start: 0,
        end: 0,
      }),
      // required: true,
    },
    differentiallyExpressedGenes:{
      type: Array,
      default: () => [],
    },
    type:{
      type: String,
      required: true
    },
    rarePathogenicTrackHintCondition:{
      type:String,
      required: true
    }
  },
  data: () => ({
    eventIdentifier: Math.floor(Math.random() * 100000000),
    showVariantDialog:false,
    selectedVariantId: null,
    selectedVariantType: null,
    coolboxData: {
      tads: [],
      connections: [],
      dynamic_tracks: [],
      enhancers: [],
      genes: [],
      transcripts: [],
      rare_pathogenic_svs: [],
      pedigree: {},
    },
    datasetFields: [],
    datasetFieldGroups: [],
    coolboxDetails: [],
  }),
  methods: {
    getImage() {
      return this.$refs.refrenceTad.getImage();
    },
    loadcoolboxDetails() {
      var id = this.datasetFields.find((item) => item.title == "ID").name;
      let idFilter = {};
      idFilter[id] = this.variantId;
      const allExactFilters = [idFilter];
      const _this = this;
      getData(this.cohortId, this.selectedDatabaseId, allExactFilters, [], [],[], _this.datasetFieldGroups, "one_of_all", 1, 0, [], [], [], ['ID','OVERLAPPING_GENES'],[], function (res) {
        _this.coolboxDetails = res.result[0];
      });
    },
    onVariantClick(variantId, varaintType, ctrlKey){
      // console.log('onVariantClick:',variantId, varaintType)
      if(variantId && varaintType ){
        if(ctrlKey){
          const routeData = this.$router.resolve({ name: "SingleVariantInfo", params: { id: this.projectId, cohortid: this.cohortId, variantId: variantId },query: { variantType: varaintType }  });
          // console.log(routeData.href)
          // window.open(routeData.href+`?variantType=${varaintType}`, "_blank").focus();
          window.open(routeData.href, "_blank").focus();
        } else {
          this.selectedVariantId = variantId
          this.selectedVariantType = varaintType
          this.showVariantDialog = true
        }
      } else {
        throw new Error(`invalid values for variantId, varaintType in onVariantClick variantId:'${variantId}', varaintType:'${varaintType}'`)
      }
    }
  },
  computed: {
    coolboxMoreDetails() {
      delete this.coolboxDetails.__usr
      delete this.coolboxDetails.__organization
      const details =  Object.keys(this.coolboxDetails).map((item) => {
        let x = {
          name: item,
          value: this.coolboxDetails[item],
        };
        const types = ["OMIM_LINK", "CLINVAR_LINK", "GENE", "SNP_LINK"];
        x["type"] = null;
        types.forEach(element => {
          // console.log('this.datasetFields',this.datasetFields, element )
          const c =this.datasetFields.find((x) => x.title == item)
          if (c && c.groups.findIndex((y) => y.groupType == element) > -1) {
            x["type"] = element;
          } 
        })

        return x;
      });
      return details
    },
    rarePathogenicSvs() {
      return [];
    },
    pedigree() {
      return this.coolboxData.pedigree;
    },
    geneEnhancers() {
      // console.log("geneEnhancers:180", this.coolboxData.genes);
      let genes = this.coolboxData.genes.map((item) => {
        let g = item;
        g["id"] = g.name;
        g["chromosome"] = g.chr;
        g["dir"] = g.direction;
        g["userSelected"]  = this.selectedUsersGenes.indexOf(g.name.toUpperCase()) >= 0
        g["differentiallyExpressed"]  = this.differentiallyExpressedGenes.indexOf(g.name.toUpperCase()) >= 0

        g["type"] = "GENE";
        return g;
      });
      let enhancers = this.coolboxData.enhancers.map((item) => {
        let e = item;
        e["id"] = e.name;
        e["chromosome"] = e.chr;

        e["dir"] = e?.direction || null;
        e["type"] = "RE";
        return e;
      });
      let connections = this.coolboxData.connections.map((c) => {
        return { from: c.enhancer, to: c.gene, color: 3 };
      });
      return { elements: genes.concat(enhancers), connections: connections };
    },
  },
  watch: {
    variantId() {
      const _this = this;
      getVariantTads(this.cohortId, this.selectedDatabaseId, this.variantId, (res) => {
        _this.coolboxData = res;
      });
      this.loadcoolboxDetails();
    },
  },
  created() {
    this.$eventBus.$on(`onVariant${this.eventIdentifier}VariantMouseClick`,(variantId, varaintType,ctrlKey )=>{ this.onVariantClick(variantId, varaintType, ctrlKey)} )

    const _this = this;
    getDatasetFields(_this.selectedDatabaseId, (res)=>{
      _this.datasetFields = res.filter(item => item.title == 'OVERLAPPING_GENES' || item.title == 'ID' || item.groups.findIndex((g) => g.groupType == "COOLBOX_DETAILS") >=0)
      _this.datasetFieldGroups = [_this.datasetFields.find(item=> item.groups.findIndex(g => g.groupType == "COOLBOX_DETAILS") >= 0).groups.find(t=>t.groupType == "COOLBOX_DETAILS").id]
      _this.loadcoolboxDetails();
    })
    
    getVariantTads(this.cohortId, this.selectedDatabaseId, this.variantId, (res) => {
      _this.coolboxData = res;
    });
  },
};
</script>
<style scoped>
.tadBody {
  background-color: #f5f5f5;
  border-bottom: 1px solid #d3d3d3 !important;
  border-right: 1px solid #d3d3d3 !important;
  border-left: 1px solid #d3d3d3 !important;
  border-radius: 0 0 8px 8px;
}
.tadHeader {
  border-top: 1px solid #d3d3d3 !important;
  border-right: 1px solid #d3d3d3 !important;
  border-bottom: 1px solid #d3d3d3 !important;
  border-left: 1px solid #d3d3d3 !important;
  border-radius: 8px 8px 0 0;
}
::v-deep .jsoneditor-vue {
  height: 100% !important;
}

::v-deep .jsoneditor-frame {
  display: none;
}
</style>