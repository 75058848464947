<template>
    <div >
        <v-rect ref="sv" :config="rectConfig"  ></v-rect>
        <!-- <v-rect :config="rectConfig"  ></v-rect> -->
        <!-- <v-text v-if="this.info.sample && this." :config="textConfig" ></v-text> -->
        <Tooltip  :region="tooltipRegion" :text="tooltipText" @mouseenter="onMouseEnter" @mouseout="onMouseLeave" @mouseclick="onMouseClick"></Tooltip> 

    </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Tooltip from '@/components/defaultAnalysis/tad/Tooltip.vue'

Vue.use(VueKonva);
import { toScatedX } from '@/utils/tad.js'
export default {
    name:'RarePathogenicSV',
    components:{Tooltip},
    props:{
        tadInfo:{
            type:Object,
            required: true
        },
        info:{
            type:Object,
            default: () => {}
        },
        title:{
            type:String,
            default: null
        },
        baseLineY:{
            type: Number,
            default:450
        },
        enableHightlighSameSamples:{
         type:Boolean,
         default: false
        },
        eventIdentifier:{
            type:String,
            required:true
        },
        clickable:{
            type: Boolean,
            default: false
        }


        // color:{
        //     type:[],
        //     required: true
        // }
        
    },
    data:()=>({
       
        selected:false,
        hover:false,
        HEIGHT: 14,
        hasNotCommonSample:false,
        show: true,
        
    }),
    mounted(){
        this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onOtherVariantMouseEnter(prm)})
        this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onOtherVariantMouseLeave(prm)})
        this.$eventBus.$on(`on${this.eventIdentifier}LegendItemClicked`,(type, show )=>{ this.onLegendClicked(type, show)} )
    },
    methods:{
        onMouseClick(r){
            // console.log(`on${this.eventIdentifier}VariantMouseClick`,this.info.id, this.info._variant_type, r.evt.ctrlKey)
            this.$eventBus.$emit(`on${this.eventIdentifier}VariantMouseClick`, this.info?.id, this.info?._variant_type, r.evt.ctrlKey)
        },
        onLegendClicked(type, show){
            if(this.info.type == type){
                // console.log('onLegendClicked',type, show)
                this.show = show
            }
        },
        onOtherVariantMouseEnter(prm){
            if(this.enableHightlighSameSamples == true && prm.sample !== null  &&  this.info.sample !== prm.sample){
                this.hasNotCommonSample = true
            }
        },
        onOtherVariantMouseLeave(){
            this.hasNotCommonSample = false            
        },
        toX(num){
            return toScatedX(num, this.tadInfo.min, this.tadInfo.scale)
        },
        onMouseEnter(){
            if(this.clickable){
                this.$refs.sv.getNode().parent.parent.container().style.cursor = "pointer";
            }
            this.$eventBus.$emit('onVariantMouseEnter', { start:this.info.start, end:this.info.end, widthInPx:Math.max(this.toX(this.info.end)- this.toX(this.info.start),this.minWidth), sample:this.info?.sample || null, id:this.info?.id || null   })
        },
        onMouseLeave(){
            if(this.clickable){
                this.$refs.sv.getNode().parent.parent.container().style.cursor = "default";
            }
            this.$eventBus.$emit('onVariantMouseLeave', { start:this.info.start, end:this.info.end, widthInPx:Math.max(this.toX(this.info.end)- this.toX(this.info.start),this.minWidth), sample:this.info?.sample||null, id:this.info?.id || null})
        },
    },
    computed:{
        minWidth(){
            return 2
        },
        tooltipText(){
            let text = (this.title? ` ⦗ ${this.title} ⦘\n`: '') 
            const infoKeys=['_variant_type','inheritance', 'type','sample','gt','chr', 'repcn', 'ref_cn', 'rep_unit','size', 'disease status','clinvar','start','end','_is_possible_compound_hetrozygous']
            const infoKeysTitles=['Variant','Inheritance','Type','Sample','GT','Chr', 'REPCN', 'REF_CN', 'REP_UNIT', 'Size', 'Disease Status','Clinvar','Start','End','Type']
            infoKeys.forEach((k,idx) => {
                const title = infoKeysTitles[idx]
                if(this.info[k] !== undefined){
                    if(k == '_is_possible_compound_hetrozygous'){
                        const v = (this.info[k] == true? 'Possible Compound Heterozygous' : 'Compound Heterozygous') 
                        text += `    ${title}: ${v} \n`
                    } else {
                        text += `    ${title}: ${this.info[k]} \n`
                    }
                }
            })
            return text.substring(0,text.lastIndexOf('\n'))
        },
        tooltipRegion(){
            return {
                x: this.toX( this.info.start) -3, 
                y: this.baseLineY -1, 
                width: Math.max(this.toX(this.info.end) - this.toX(this.info.start) + 4 ,9) , 
                height: this.HEIGHT + 5, 
            }         
        },
        textConfig(){
            return {
                x: this.toX(this.info.start) - 5,
                y: this.baseLineY - 5, 
                width: 50, 
                text: this.info.type, 
                fontSize: 10, 
                fill:'navy',
                align:'left',
            } 
        },
        rectConfig(){
            
            return {
                x: this.toX(this.info.start) , 
                y: this.baseLineY +2, 
                width: Math.max(this.toX(this.info.end)- this.toX(this.info.start),this.minWidth)  , 
                height: this.HEIGHT, 
                opacity: this.hasNotCommonSample || !this.show ? 0.2 :1,
                fill: this.info.color,
                shadowBlur: this.hover? 3 : 0,
            }
        },
    }
    
}
</script>