import { render, staticRenderFns } from "./SampleVariants.vue?vue&type=template&id=535524cd&scoped=true"
import script from "./SampleVariants.vue?vue&type=script&lang=js"
export * from "./SampleVariants.vue?vue&type=script&lang=js"
import style0 from "./SampleVariants.vue?vue&type=style&index=0&id=535524cd&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "535524cd",
  null
  
)

export default component.exports