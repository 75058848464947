<template>
  <div>
    <div class="d-flex flex-column mb-1">
      <div class="text-h5 font-weight-medium"><v-icon class="mb-1 mr-1" color="primary">mdi-account-injury-outline</v-icon>Samples</div>
      <div class="d-flex flex-row justify-center filterFrame flex-grow-1 mt-4">
        <span class="body-2 grey--text text--darken-2 mt-2 mr-2 text-no-wrap">Terms and Phenotypes:</span>
        <v-combobox
          :return-object="false"
          validate-on-blur
          deletable-chips
          small-chips
          multiple
          hide-details
          dense
          outlined
          :menu-props="{ offsetY: true }"
          class="pt-0 Filter limited-height-combobox"
          clearable
          :items="hpoTerms"
          v-model="filteredHPOTerms"
        ></v-combobox>
      </div>
      <div class="d-flex flex-row mb-4 d-flex flex-row flex-wrap">
        <div class="d-flex flex-row justify-start filterFrame flex-grow-1">
          <!-- <span class="body-2 grey--text text--darken-2 mt-2 mr-2">Disease Status:</span> -->
          <v-checkbox dense v-model="selectedDiseaseStatuses" hide-details @change="savePrefs" v-for="item in diseaseStatuses" :value="item.value" :key="item.value">
            <template v-slot:label>
              <span class="caption ml-n2">{{ item.title }}</span>
            </template>
          </v-checkbox>
        </div>
        <div class="d-flex flex-row justify-center filterFrame flex-grow-1 mx-4">
          <v-checkbox dense v-model="filteredDecisions" hide-details @change="savePrefs" v-for="item in Object.keys(sampleDecisions)" :value="item" :key="item">
            <template v-slot:label>
              <span class="caption ml-n2">{{ sampleDecisions[item].text }}</span>
            </template>
          </v-checkbox>
        </div>
        <div class="d-flex flex-row justify-center filterFrame flex-grow-1 mx-4 px-4">
          <v-checkbox dense v-model="filteredStatuses" hide-details @change="savePrefs" v-for="item in sampleStatues" :value="item.name" :key="item.name">
            <template v-slot:label>
              <span class="caption ml-n2">{{ item.msg }}</span>
            </template>
          </v-checkbox>
        </div>

        <div class="d-flex flex-row justify-center filterFrame flex-grow-1 px-4">
          <v-checkbox dense v-model="hasData" hide-details @change="savePrefs">
            <template v-slot:label>
              <span class="caption ml-n2">Samples with data</span>
            </template>
          </v-checkbox>
        </div>

        <div class="d-flex flex-row justify-end filterFrame flex-grow-1 mx-4 px-4">
          <v-checkbox dense v-model="filteredSexes" hide-details @change="savePrefs" v-for="item in sexes" :value="item" :key="item">
            <template v-slot:label>
              <span class="caption ml-n2">{{ item }}</span>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <v-data-table
      :items-per-page="30"
      :loading="loading"
      loading-text="Retrieving samples ..."
      ref="detailGrid"
      :headers="headers"
      :items="sampleList"
      @click:row="clickRow"
      class="elevation-1 mb-8"
      :item-key="'sample_name'"
      single-select
      v-model="selected"
      :search="searchedFieldName"
    >
      <template v-slot:header.sample_name="{}">
        <v-text-field color="white" dark outlined dense v-model="searchedFieldName" append-icon="mdi-magnify" label="Sample Name" class="mt-2 mb-1 white--text" style="width: 250px !important" single-line hide-details></v-text-field>
      </template>
      <template v-slot:item.disease_status="{ item }">
        <div>{{ item.disease_status.name }} <v-icon x-small color="red" class="mb-3" v-if="item.disease_status.name == 'affected'">mdi-asterisk</v-icon></div>
      </template>
      <template v-slot:item.hrStatus="{ item }">
        <v-tooltip bottom :open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" :color="item.hrStatus.color"> {{ item.hrStatus.icon }} </v-icon>
          </template>
          <span>{{ item.hrStatus.msg }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.hpo_terms="{ item }">
        <div class="pb-1">
          <v-chip class="mt-1 lighten-3" color="grey" v-for="term in item.hpo_terms.slice(0, 5)" :key="term"> {{ term }}</v-chip>
          <v-tooltip v-if="item.hpo_terms.length > 5" bottom :open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs" class="mt-1 lighten-3" color="grey" label>...</v-chip>
            </template>
            <div class="d-flex flex-wrap py-2" style="max-width: 800px">
              <v-chip class="mt-1 lighten-3" color="grey" v-for="term in item.hpo_terms.slice(5)" :key="term"> {{ term }}</v-chip>
            </div>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.has_variant="{ item }">
        <v-icon x-small color="primary" class="mb-3" v-if="item.has_variant">mdi-check</v-icon>
      </template>
      <template v-slot:item.final_decision="{ item }">
        <!-- {{item.final_decision}} -->
        <v-tooltip v-if="item.final_decision != null"  bottom :open-delay="300">
          <template v-slot:activator="{ on, attrs }">
            <v-icon  v-bind="attrs" v-on="on" :color="item.final_decision.color"> {{ item.final_decision.icon }} </v-icon>
          </template>
          <span>{{ item.final_decision.text }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import loadSampleList from "@/mixins/lucidAllAnalysis/loadSampleList.js";
import { save_user_pref, get_user_pref } from "@/utils/userPreferences.js";
export default {
  name: "LucidAllAnalysisSampleList",
  mixins: [loadSampleList],
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      hasData: true,
      searchedFieldName: null,
      selected: [],
      loading: true,
      headers: [
        { text: "Sample", align: "center", sortable: false, value: "sample_name", class: "primary white--text font-weight-meduim text-center", width: "250", cellClass: "variantCell" },
        { text: "Family", value: "family_id", class: "primary white--text  ", cellClass: "variantCell", sortable: false, align: "center" },
        { text: "Sex", value: "sex", class: "primary white--text  ", cellClass: "variantCell ", sortable: false, align: "center" },
        { text: "Disease Status", value: "disease_status", class: "primary white--text  ", cellClass: "variantCell text-uppercase", sortable: false, align: "center" },
        { text: "Phenotype & Terms", value: "hpo_terms", class: "primary white--text  ", cellClass: "variantCell", sortable: false, align: "center" },
        { text: "Analysis Status", value: "hrStatus", class: "primary white--text text-center", width: "80",  cellClass: "variantCell", sortable: false, align: "center" },
        { text: "Final Decision", align: "center", sortable: false, value: "final_decision", width: "80", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
        { text: "Data", align: "center", sortable: false, value: "has_variant", width: "50", class: "primary white--text font-weight-meduim text-center", cellClass: "variantCell" },
      ],
      filteredDecisions: ["2", "3", "0", "1"],
      filteredStatuses: ["NOT_ANALYZED", "IN_PROGRESS", "DONE"],
      filteredSexes: ["Female", "Male", "Unknown"],
      sexes: ["Female", "Male", "Unknown"],
      hpoTerms: [],
      filteredHPOTerms: [],

      diseaseStatuses: [
        { title: "Affected", value: "affected", color: "red" },
        { title: "Unaffected", value: "unaffected", color: "grey" },
      ],
      sampleDecisions: {
        0: {
          color: "grey",
          icon: "mdi-tag-search-outline",
          text: "Not Decided",
        },
        1: {
          color: "green",
          icon: "mdi-tag-check-outline",
          text: "Solved",
        },
        2: {
          color: "red lighten-2",
          icon: "mdi-tag-remove-outline",
          text: "Unsolved",
        },
        3: {
          color: "orange",
          icon: "mdi-tag-hidden",
          text: "Unsolved with Strong Candidates",
        },
      },
      selectedDiseaseStatuses: ["affected"],
      selectedSample: null,
      // samples: null,
    };
  },
  methods: {
    clickRow(item) {
      this.$router.push({ name: "LucidAllAnalysisSampleVariants", params: { id: this.projectId, cohortid: this.cohortId, sampleid: item.id } });
    },
    onSamplesLoadedCallback() {
      this.loading = false;

      let hpoterms = new Set();
      this.samples.forEach((sample) => {
        sample.hpo_terms.forEach((h) => hpoterms.add(h));
      });
      this.hpoTerms = Array.from(hpoterms).sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
      // this.filteredHPOTerms = this.hpoTerms;
    },
    savePrefs() {
      const p = {
        selectedDiseaseStatuses: this.selectedDiseaseStatuses,
        filteredDecisions: this.filteredDecisions,
        filteredStatuses: this.filteredStatuses,
      };
      save_user_pref("IndividualAnalysisSamplesFilters", p);
    },
  },

  computed: {
    decisionStats() {
      let stats = {
        "Not Decided": 0,
        Solved: 0,
        Unsolved: 0,
        "Unsolved with Strong Candidates": 0,
      };
      this.sampleList.forEach((item) => {
        stats[item?.decision?.title || "Not Decided"]++;
      });
      return stats;
    },
    statusStats() {
      let stats = {
        Analyzed: 0,
        "Analysis in Progress": 0,
        "Not Analyzed": 0,
      };
      this.sampleList.forEach((item) => {
        stats[item.hrStatus.msg]++;
      });
      return stats;
    },
    sampleList() {
      let r = this.samples.map(item => {
        let x = item
        x.final_decision=null
        if(x.status.decision !== null){
          x.final_decision=this.sampleDecisions[x.status.decision.id]
        } 
        return x
      })
      r = r.filter((item) => this.selectedDiseaseStatuses.indexOf(item.disease_status.name) >= 0)
        .filter((item) => this.filteredStatuses.indexOf(item.status.status) >= 0)
        .filter((item) => this.filteredDecisions.indexOf((item.status.decision?.id || 0).toString()) >= 0);
      if (this.hasData) {
        r = r.filter((item) => item.has_variant);
      }
      if (this.filteredSexes.indexOf("Male") == -1) {
        r = r.filter((item) => item.sex != "male");
      }
      if (this.filteredSexes.indexOf("Female") == -1) {
        r = r.filter((item) => item.sex != "female");
      }
      if (this.filteredSexes.indexOf("Unknown") == -1) {
        r = r.filter((item) => item.sex == "female" || item.sex == "male");
      }
      if (this.filteredHPOTerms.length > 0) {
        r = r.filter((item) => item.hpo_terms.some((term) => this.filteredHPOTerms.includes(term)));
      }
      return r;
    },
  },
  created() {
    this.onDataLoadedCallback = this.onSamplesLoadedCallback;
    this.loadSamples(this.projectId, this.cohortId);
    const savedFilters = get_user_pref("IndividualAnalysisSamplesFilters");
    if (savedFilters) {
      this.selectedDiseaseStatuses = savedFilters.selectedDiseaseStatuses;
      this.filteredDecisions = savedFilters.filteredDecisions;
      this.filteredStatuses = savedFilters.filteredStatuses;
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-text-field fieldset,
.v-text-field .v-input__control,
.v-text-field .v-input__slot {
  border-color: #cccccc !important;
  // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.07) !important;
}

::v-deep .SampleList.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.14) !important;
}

::v-deep tr.v-data-table__selected {
  background: var(--v-accent-base) !important;
  border-bottom: 0px !important;
}

.theme--light.v-data-table::v-deep {
  background-color: transparent;
}
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr > td.variantCell {
  height: 45px !important;
  border-bottom: 1px solid #0393a850 !important;
}

.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__expanded__content):hover {
  cursor: pointer;
}

.filterFrame {
  background-color: white;
  // padding-left: auto;
  // padding-right: auto;
  padding-bottom: 6px;
  padding-top: 2px;
  border-radius: 6px;
}
</style>