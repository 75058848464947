<template>
  <v-tooltip bottom transition="slide-x-transition" color="transparent" :open-delay="400">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="mt-1 ml-4 primary--text caption text-decoration-underline hintText" style="text-decoration-style: dotted !important">
        <!-- <v-icon x-small color="primary">mdi-help</v-icon> -->
         {{ text }}
      </div>
    </template>
    <div class="slotBox">
      <div v-if="!!title" class="font-weight-bold text-body-1 mt-1 mb-2">{{ title }}</div>
            <ul v-if="items.length>1">
              <li v-for="(item,index) in items" style="list-style: none" :key="index"><v-icon>mdi-menu-right</v-icon>{{ item }}</li>
            </ul>
            <div v-else-if="items.length==1">
              {{ items[0] }}
            </div>
      <slot></slot>
    </div>
  </v-tooltip>
</template>
<script>
export default {
  name: "HoverHint",
  props: {
    text: {
      type: String,
      required: true,
    },
    textClass: {
      type: String,
      default: "",
    },
    title:{
      type: String,
      default:''
    },
    items:{
      type:Array,
      default: ()=>[]
    }
  },
};
</script>
<style scoped>
.slotBox {
  width: 580px;
  color: rgb(71, 71, 71);
  /* border: 1px solid #0392a849 !important; */
  background-color: white !important;
  -webkit-box-shadow: 0px 0px 4px 13px rgba(255,255,255,1);
  -moz-box-shadow: 0px 0px 4px 13px rgba(255,255,255,1);
  box-shadow: 0px 0px 4px 13px rgba(255,255,255,1);
  /* opacity: 10 !important; */
  /* border-radius: 8px; */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
  margin-right: 0px;
  /* background-color: ; */
  /* backdrop-filter: blur(3px) !important; */
}
.hintText:hover {
  cursor: help;
}
</style>