<template>
    <div >
        <v-rect :config="rectConfig"  ></v-rect>
        <v-text :config="textConfig" ></v-text>
        <!-- <v-rect :config="shadowRectConfig" ></v-rect> -->
        <!-- <v-text  :config="startTextConfig" ></v-text> -->
        <v-text :config="endTextConfig" ></v-text>
        <Tooltip  :region="tooltipRegion" :text="tooltipText" @mouseover="onMouseEnter" @mouseout="onMouseLeave" ></Tooltip> 

    </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Tooltip from '@/components/defaultAnalysis/tad/Tooltip.vue'
import variantTypeColors from "@/mixins/varaintTypeColors.js";

Vue.use(VueKonva);
import { toScatedX } from '@/utils/tad.js'
export default {
    name:'Mutation',
    components:{Tooltip},
    mixins: [variantTypeColors],

    props:{
        tadInfo:{
            type:Object,
            required: true
        },
        info:{
            type:Object,
            default: () => {}
        },
        baseLineY:{
            type: Number,
            default:450
        },
        
    },
    data:()=>({

        selected:false,
        hover:false
    }),
    methods:{
        toX(num){
            return toScatedX(num, this.tadInfo.min, this.tadInfo.scale)
        },
        onMouseEnter(){
            // const container = e.target.getStage().container();
            // container.style.cursor = "pointer";
            this.hover = true
            this.$emit('onMutationMouseOver', this.info)
        },
        onMouseLeave(){
            // const container = e.target.getStage().container();
            // container.style.cursor = "default";
            this.hover = false
            this.$emit('onMutationMouseOut', this.info)

        },
        onMouseClick(){
            this.selected = !this.selected
            this.$emit('onChanged',this.info.id, this.selected)
        }
    },
    watch:{
        'tadInfo.min':{
            handler:function(){
            this.$emit('onSvBar',this.toX(this.info.start), Math.max(this.toX(this.info.end)- this.toX(this.info.start),this.minWidth))
            },
            deep: true
        }
    },
    computed:{
        colors(){
            return this.VARIANT_COLORS
        },
        minWidth(){
            if(this.info.isModified && this.info.type=='DEL'){
                return 1
            }
            return 3
        },
        tooltipText(){
            let text = `Type: ${this.info.type} \n`
            if(this.info.repcn){
                text += `REP-CN: ${this.info.repcn} \nREF-CN: ${this.info.ref_cn || ''} \nREP-UNIT: ${this.info.rep_unit || ''} \n`
            } 
            text += `Size: ${this.info.size}\nStart: ${this.info.start}\nEnd:   ${this.info.end}\nDescription: ${this.info?.info || ''}` 
            return text
        },
        tooltipRegion(){
            return {
                x: this.toX( this.info.start) - 4, 
                y: this.baseLineY -5, 
                width: Math.max(this.toX(this.info.end) - this.toX(this.info.start) +8 ,18) , 
                height: 32, 
            }         
        },
        textConfig(){
            return {
                x: this.toX(this.info.start) - 5,
                y: this.baseLineY - 5, 
                width: 50, 
                text: this.info.type, 
                fontSize: 12, 
                fontStyle: 'bold',
                fill:'navy',
                align:'left',
                // onMouseEnter: this.onMouseEnter,
                // onMouseLeave: this.onMouseLeave,
                // onClick: this.onMouseClick
            } 
        },
        startTextConfig(){
            return {
                x: this.toX(this.info.start) ,
                y: this.baseLineY+3, 
                width: 100  , 
                text: this.info.start, 
                fontSize: 10, 
                fill:'grey',
                align:'left',
            } 
        },
        endTextConfig(){
            return {
                x: this.toX(this.info.end )+5 ,
                y: this.baseLineY+10, 
                width: 100 , 
                text: (this.info.repcn ? `REP-CN: ${this.info.repcn}` : `Size: ${this.info.size}`), 
                fontSize: 11, 
                fill:'#666666',
                align:'left',
            } 
        },
        rectConfig(){
            
            return {
                x: this.toX(this.info.start) , 
                y: this.baseLineY +8, 
                // width: 50 , 
                width: Math.max(this.toX(this.info.end)- this.toX(this.info.start),this.minWidth)  , 
                height: 18, 
                stroke: 'black',
                // strokeWidth: this.selected? 1 : 0 , 
                strokeWidth:  1  , 
                // fill: this.colors[this.info.type],
                fill: this.colors[this.info.type],
                shadowBlur: this.hover? 3 : 0,
                // onMouseEnter: this.onMouseEnter,
                // onMouseLeave: this.onMouseLeave,
                // onClick: this.onMouseClick
                // cornerRadius: 2,
            }


        },
        shadowRectConfig(){
            return {
                x: this.toX(this.info.start),
                y: this.baseLineY + 22, 
                width: Math.max(this.toX(this.info.end) - this.toX(this.info.start),this.minWidth) , 
                height: 20, 
                strokeWidth: 0 , 
                fill: 'grey',
                opacity:0.1
            }


        }

    }
    
}
</script>