<template>
  <div>
    <template v-if="showEnhancers">
      <v-rect :config="reRectConfig"></v-rect>
      <v-text :config="reTrackTitleTextConfig"></v-text>
      <RE :tadInfo="tadInfo" v-for="r in elements" :key="r.id" :spec="r" :baseLineY="baseLineY - 25"></RE>
    </template>
    <v-rect :config="geneRectConfig"></v-rect>
    <Legend eventIdentifier="Gene" :items="legendItems" :x="legendX" :y="legendY"></Legend>
    <HighlightBar :startX="svHighlightInfo.start" :width="svHighlightInfo.width" :height="geneTrackHeight + reTrackHeight" :baseLineY="baseLineY - reTrackHeight"></HighlightBar>
    <HighlightBar :startX="secondaryHighlightStart" :width="secondaryHighlightWidth" :height="geneTrackHeight + reTrackHeight" :baseLineY="baseLineY - reTrackHeight"></HighlightBar>
    
    <v-text :config="geneTrackTitleTextConfig"></v-text>

    <template v-if="showConnections">
      <Connector  :tadInfo="tadInfo" v-for="(c, index) in lineConnections" :key="index" :fromBaseLineY="baseLineY - 5" :toBaseLineY="baseLineY + 10 + geneTrackPaddingTop" :spec="c" :lineHeight="geneLineHeight"></Connector>
    </template>
    <template v-if="showGenes">
      <Gene :tadInfo="tadInfo" v-for="(g, idx) in linedGenes.genes" :key="g.id + idx" :spec="g" :baseLineY="baseLineY + g.line * geneLineHeight + geneTrackPaddingTop"></Gene>
    </template>
    <TrackSeparator :tadInfo="tadInfo" :y="baseLineY+ geneTrackHeight +1"></TrackSeparator>
  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Gene from "@/components/defaultAnalysis/tad/Gene.vue";
import RE from "@/components/defaultAnalysis/tad/RE.vue";
import HighlightBar from "@/components/defaultAnalysis/tad/HighlightBar.vue";
import Connector from "@/components/defaultAnalysis/tad/Connector.vue";
import TrackSeparator from "@/components/defaultAnalysis/tad/TrackSeparator.vue";
import { toScatedX } from "@/utils/tad.js";
import Legend from "@/components/defaultAnalysis/tad/Legend.vue";
import {getTextSizeInPixel} from "@/utils/textUtils.js"

Vue.use(VueKonva);
export default {
  name: "GeneTrack",
  components: { Gene, RE, Connector, HighlightBar, Legend, TrackSeparator },
  // components:{Gene,RE, Connector},
  props: {
    tadInfo: {
      type: Object,
      required: true,
    },
    genes: {
      type: Array,
      default: () => [],
    },
    elements: {
      type: Array,
      default: () => [],
    },
    connections: {
      type: Array,
      default: () => [],
    },
    baseLineY: {
      type: Number,
      default: 500,
    },
    svHighlightInfo: {
      type: Object,
      required: true,
    },
    showEnhancers:{
      type: Boolean,
      default: true
    },
    legendItems:{
      type: Array,
      required: true
    },
    showConnections:{
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showGenes: true,
    stepCount: 10,
    geneLineHeight: 35,
    geneTrackPaddingTop: 30,
    secondaryHightlightInfo: {
      start: 0,
      end: 0,
      widthInPx: 0,
    },
    showSecondaryHighlight: false,
    LEGEND_HEIGHT: 20,
  }),
  mounted(){
    // if(!this.showEnhancers){
    //    this.legendItems.splice(1,1)
    // }
    this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onVariantMouseEnter(prm)})
    this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onVariantMouseLeave(prm)})
  },

  methods: {
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    onVariantMouseEnter(prm){
      this.secondaryHightlightInfo  = prm
      this.showSecondaryHighlight = true
    },
    onVariantMouseLeave(){
      this.showSecondaryHighlight = false
    },
  },
  watch: {
    showConnections(val, oldVal){
      if(!oldVal && val){
        this.showGenes = false
        this.$nextTick(()=>{this.showGenes = true})
        // setTimeout(()=>{this.showGenes = true}, 1000)
        
      }
    },
    showEnhancers(val, oldVal){
      if(!oldVal && val){
        this.showGenes = false
        this.$nextTick(()=>{this.showGenes = true})
        // setTimeout(()=>{this.showGenes = true}, 1000)
        
      }
    },    
    linedGenes: {
      handler: function () {
        this.$emit("onGeneTrackHeight", this.geneTrackHeight);
        // console.log('caught!');
      },
      deep: true,
    },
  },
  computed: {
    legendX() {
      let str = ""
      let symbols = 0
      this.legendItems.forEach((item)=> {
        str += item.text + " "
        symbols += ( item.type == 'line'? 2 : 1 )
      })
      // console.log(str, symbols, getTextSizeInPixel(this, str, 10 ))
      return  this.toX(this.tadInfo.max) - (getTextSizeInPixel(this, str, 12 ) + symbols * 30 )
      //return  this.toX(this.tadInfo.max) - this.legendItems.length*142
      // return this.showEnhancers ? this.toX(this.tadInfo.max) - 1000 : this.toX(this.tadInfo.max) - 790;
      
    },
    legendY() {
      return this.baseLineY + this.geneTrackHeight - this.LEGEND_HEIGHT ;
    },
    secondaryHighlightStart(){
      return this.toX(this.secondaryHightlightInfo.start)
    },
    secondaryHighlightWidth(){
      return this.showSecondaryHighlight? this.secondaryHightlightInfo.widthInPx : 0
    },
    geneTrackTitleTextConfig() {
      return {
        x: 3,
        y: this.baseLineY + 2,
        text: "Ensemble Genes",
        fontSize: 13,
        fontStyle: "bold",
        fill: "#555555",
      };
    },
    reTrackTitleTextConfig() {
      return {
        x: 3,
        y: this.baseLineY - 17,
        text: "Enhancers",
        fontSize: 13,
        fontStyle: "bold",
        fill: "#555555",
      };
    },
    lineConnections() {
      if(!this.showConnections || !this.showEnhancers){
        return []
      }
      let cn = this.connections.map((item) => {
        let c = item;
        c["line"] = this.linedGenes.genes.find((g) => g.id == c.toId).line;
        return c;
      });
      return cn;
    },
    linedGenes() {
      const lines = [];

      let gn = this.genes;
      gn.sort((a, b) => a.start - b.start).forEach((g) => {
        let added = false;
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].findIndex((l) => this.toX(g.start) - 5 < this.toX(l.end)) < 0) {
            // there is a coverage
            lines[i].push(g);
            g["line"] = i;
            added = true;
            break;
          }
        }
        if (!added) {
          lines.push([g]);
          g["line"] = lines.length - 1;
        }
      });
      gn.map((item) => {
        item["affected"] = this.connections.findIndex((c) => c.toId == item.id) > 0;
      });
      // console.log(lines)
      // console.log(gn)
      return { genes: gn, lines: lines };
    },
    start() {
      return this.tadInfo.min;
    },
    end() {
      return this.tadInfo.max;
    },
    geneTrackHeight() {
      return this.linedGenes.lines.length * this.geneLineHeight + 20 + this.geneTrackPaddingTop + this.LEGEND_HEIGHT;
    },
    reTrackHeight() {
      return 20;
    },
    geneRectConfig() {
      return { x: this.toX(this.start), y: this.baseLineY, width: this.toX(this.end) - this.toX(this.start), height: this.geneTrackHeight, strokeWidth: 0,  };
    },
    reRectConfig() {
      return { x: this.toX(this.start), y: this.baseLineY - 20, width: this.toX(this.end) - this.toX(this.start), height: this.reTrackHeight, strokeWidth: 0, fill: "#DDDDDD" };
    },
  },
};
</script>