<template>
    <VariantInfo :cohortId = "cohortId" :projectId = "projectId" :sampleId = "sampleId" :variantId = "variantId"  :variantType = "variantType" :datasetId = "datasetId" ></VariantInfo>
</template> 
<script>
import VariantInfo from '@/components/Common/VariantInfo.vue'
export default {
    name:'SingleVariantInfo',
    components:{VariantInfo},
    data:()=>({
        cohortId: null,
        projectId: null,
        variantId: null,
        variantType: null,
        sampleId: null,
        datasetId: null
    }),
    created(){
        this.cohortId =  Number(this.$route.params.cohortid)
        this.projectId = Number(this.$route.params.id)
        this.variantId = this.$route.params.variantId
        this.sampleId  = Number(this.$route.query.sampleid)
        this.variantType = this.$route.query.variantType
        this.datasetId = this.$route.query.datasetId
    }
}
</script>