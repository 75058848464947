<template>
  <div ref="hand">
    <!-- <v-line v-if="spec.userSelected" :config="userSelectedRectConfig"></v-line> -->
    <v-line ref="line" :config="rectConfig"></v-line>
    
    <v-line v-for="(border,index) in additionalBordersConfig" :config="border" :key="index"></v-line>
    <v-rect v-for="(x,i) in hatchedBars" :config="x" :key="`dd${i}`"></v-rect>

    <v-text :config="textConfig"></v-text>
    <Tooltip :region="tooltipRegion" :text="spec.id + ' [' + spec.start + ',' + spec.end + '] :' + '\n'+ getPretty(spec.info)" @mouseenter="onMouseEnter" @mouseout="onMouseOut" @mouseclick="onMouseClick"></Tooltip>
  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Tooltip from "@/components/defaultAnalysis/tad/Tooltip.vue";
import { toScatedX } from "@/utils/tad.js";
import { getGene } from "@/api/gene/gene.js";
import {getTextSizeInPixel} from "@/utils/textUtils.js"

Vue.use(VueKonva);
// import iconImage from '@/assets/img/white-favicon.png'
export default {
  name: "Gene",
  components: { Tooltip },
  data: () => ({
    height: 15,
    showTitle: false,
    timer: null,
    secondaryHightlightInfo: {
      start: 0,
      end: 0,
      widthInPx: 0,
    },
    showSecondaryHighlight: false,
    show: true,
    patternImage: new Image(),
    AFFECTED_GENE_BORDER_COLOR:'#999999',
    HIT_GENE_BORDER_COLOR: 'black',
    USERSELECTED_GENE_BORDER_COLOR:'green',
    
  }),
  props: {
    tadInfo: {
      type: Object,
      required: true,
    },
    spec: {
      id: null,
      start: null,
      end: null,
      dir: null,
      info: null,
      color: null,
      colorStr: null,
      affected: false,
      userSelected: false,
      differentiallyExpressed: false,
    },
    baseLineY: {
      type: Number,
      default: 500,
    },
    dir: {
      type: String,
      default: null,
    },
  },
  created() {
  },
  mounted(){
    this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onVariantMouseEnter(prm)})
    this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onVariantMouseLeave(prm)})
    this.$eventBus.$on('onGeneLegendItemClicked',(type, show )=>{ this.onLegendClicked(type, show)} )

    // this.patternImage.src = 'https://dolphinai.ir/_nuxt/img/logo.f7c7cbc.png'
    // this.patternImage.onload = () => {
    //   this.$refs.line.getNode().getStage().draw()
    //   // this.$refs.stage.getStage().draw()
    // }
  },

  methods: {
    onLegendClicked(type, show){
            // if(this.info.type == type){
                console.log('onLegendClicked',type, show, this.spec.info)
                // this.show = show
            // }
        },
    getPretty(val){
      let str= JSON.stringify( val)
      let result = str.replace(/\[([^\]]*)\]/g, (match) => {
        return match.replace(/,/g, ' ');
      });
      result = result.replaceAll(':{',':\n   ')
              .replaceAll('},','\n')
              .replaceAll(',','\n   ')
              .replaceAll(':',': ')
              .replaceAll('"','')
              .replaceAll('{','')
              .replaceAll('}','')
      // console.log(result)
      return result
    },
    onVariantMouseEnter(prm){
      this.secondaryHightlightInfo  = prm

      this.showSecondaryHighlight = true
    },
    onVariantMouseLeave(){
      this.showSecondaryHighlight = false
    },
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    onMouseEnter(){
      this.$refs.line.getNode().parent.parent.container().style.cursor = 'pointer'
    },
    onMouseOut(){
      this.$refs.line.getNode().parent.parent.container().style.cursor = 'default'
    },
    onMouseClick() {
      const _this = this
      getGene(
            _this.spec.id,
            (res) => {
              if (res.omim) {
                const url = "https://www.omim.org/entry/" + res.omim + "?search=" + _this.spec.id + "&highlight=" + _this.spec.id ;
                window.open(url, "_blank").focus();
              } else {
                _this.$root.notify.show({message:`OMIM id for ${_this.spec.id} does not exist`, type:'warning'})
              }
            },
            () => {
              _this.$root.notify.show({message:`${_this.spec.id} doesn't exist in the knowledge base`, type:'warning'})
            },
            false
          );
    },
    getAdditionalBorderConfig(level, color, style){
      let points = [...this.rectPoints]
      if (this.spec.dir == "+") {
        points[0]-=level
        points[1]-=level
        points[2]+=level
        points[3]-=level
        points[4]+=level
        points[6]+=level
        points[7]+=level
        points[8]-=level
        points[9]+=level
      }else{
        points[0]-=level
        points[1]-=level
        points[2]+=level
        points[3]-=level
        points[4]+=level
        points[5]+=level
        points[6]-=level
        points[7]+=level
        points[8]-=level
      }
      return {
        points: points,
        closed: true,
        strokeWidth: 1.7,
        stroke: color ,
        dash: style
      };
    },
    // objectToStr(obj){
    //   let res = ''
    //   if(typeof obj === 'object'){
    //     if (Array.isArray(obj)){
    //       return obj.map(item => this.objectToStr(item)).join(', ')
    //     } else {
    //       Object.keys(obj).forEach( k => {
    //         if(Array.isArray(obj[k])){
    //           res += k + ': '+ this.objectToStr() obj[k].map(item => this.objectToStr(item)).join(', ') + '\n'
    //         } else if (typeof obj[k] === 'object'){
    //           res += this.objectToStr(obj[k])
    //         }
    //       })
    //     }
    //   }
    // }
  },
  computed: {
    additionalBordersConfig(){
      let borders=[]
      let index = 1
      let step = 2
      if(this.spec.affected){
        borders.push(this.getAdditionalBorderConfig(index, this.AFFECTED_GENE_BORDER_COLOR,null))
        index+=step
      }
      if(this.isHit){
        borders.push(this.getAdditionalBorderConfig(index,this.HIT_GENE_BORDER_COLOR,null))
        index+=step
      }
      if(this.spec.userSelected){
        borders.push(this.getAdditionalBorderConfig(index, this.USERSELECTED_GENE_BORDER_COLOR,[3,2]))
        index+=step
      }

      return borders
    },
    isHit(){
      // return this.spec.isHit || this.spec.userSelected || (this.showSecondaryHighlight && this.secondaryHightlightInfo.start <= this.spec.end && this.secondaryHightlightInfo.end >= this.spec.start  ) 
      return this.spec.isHit || (this.showSecondaryHighlight && this.secondaryHightlightInfo.start <= this.spec.end && this.secondaryHightlightInfo.end >= this.spec.start  ) 
    },
    tooltipRegion() {
      return {
        x: this.toX(this.spec.start) - 2,
        y: this.baseLineY + 10,
        width: this.toX(this.spec.end) - this.toX(this.spec.start) + 8,
        height: this.height,
      };
    },
    hatchedBars() {
      if(! this.spec.differentiallyExpressed){
        return []
      }
      const hatchedCount = 2
      const hatchedSize = this.height / (hatchedCount * 2 + 1)
      let res= []
      for(let i=0; i<hatchedCount;i++){
        res.push( {
          x: this.toX(this.spec.start) -1,
          y: 10 + this.baseLineY + ( i * 2 + 1 ) * hatchedSize ,
          width: this.toX(this.spec.end) - this.toX(this.spec.start) +4 ,
          height: hatchedSize,
          fill:"#ffffff70"
        } )
      }
      return res;
    },
    textConfig() {
      const isTextOutside = getTextSizeInPixel(this, this.spec.id, 'normal 10px Roboto') > (this.toX(this.spec.end) -  this.toX(this.spec.start))
      return {
        x: this.toX(this.spec.start) + (isTextOutside ? -3 : 3),
        y:  isTextOutside ? this.baseLineY -6 :this.baseLineY + 12 ,
        width: 200,
        text: this.isHit || this.spec.userSelected  ?  this.spec.id : '',
        fontSize: 12,
        fill: this.spec.color == 2 && !isTextOutside ? "white" : "black",
        // fill:  "white" ,
        align: "left",
        fontStyle: 'bold'
      };
    },
    rectPoints() {
      let start = this.toX(this.spec.start)
      let end = this.toX(this.spec.end)
      if (this.spec.start - this.tadInfo.min < 3 ) {
        start = start + 2 
      } 
      if (this.tadInfo.max -  this.spec.end < 3 ) {
        end = end - 3 
      } 
      let x = [start, this.baseLineY + 10, end + 2, this.baseLineY + 10, end + 2, this.baseLineY + 10 + this.height, start, this.baseLineY + 10 + this.height];
      if (this.spec.dir == "+") {
        x.splice(4, 0, end + 2 + 3);
        x.splice(5, 0, this.baseLineY + 10 + this.height / 2);
      } else {
        x.push(start - 3);
        x.push(this.baseLineY + 10 + this.height / 2);
      }
      return x;
    },
    rectConfig() {
      return {
        points: this.rectPoints,
        // strokeWidth:0 ,
        fill: this.spec.colorStr,
        closed: true,
        strokeWidth: 0,
        stroke: "#FFFFFF",
        // stroke: this.isHit ? "brown" : this.spec.affected ? "#888888" : "#FFFFFF",
      };
    },
    userSelectedRectConfig() {
      let points = [...this.rectPoints]
      if (this.spec.dir == "+") {
        points[0]--
        points[1]--
        points[2]++
        points[3]--
        points[4]++
        // points[5]--
        points[6]++
        points[7]++
        points[8]--
        points[9]++
      }else{
        points[0]--
        points[1]--
        points[2]++
        points[3]--
        points[4]++
        points[5]++
        points[6]--
        points[7]++
        points[8]--
        // points[9]++
      }
      return {
        points: points,
        closed: true,
        strokeWidth: 2,
        stroke: "#0000FF99" ,
        dash:[2,2]
      };
    },    
  },
};
</script>
<style scoped>
.hand:hover {
  cursor: pointer;
}
</style>