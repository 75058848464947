 <template>
  <div class="body-2">
    <v-row dense class="mt-2 px-4" :class="isPopup ? 'grey lighten-5' : ''">
      <v-col cols="4" class="font-weight-medium">Case Name </v-col>
      <v-col cols="8">
        {{ sampleInfo.sample_name }} - <span :style="'color:' + getDiseaseColor(sampleInfo.disease_status.name)">{{ sampleInfo.disease_status.name }}</span>
      </v-col>
    </v-row>
    <v-row v-if="!isPopup" dense class="grey lighten-5 px-4">
      <v-col cols="4" class="font-weight-medium">Reference Genome</v-col>
      <v-col cols="8">GRCh38</v-col>
    </v-row>
    <v-row dense class="px-4">
      <v-col cols="4" class="font-weight-medium">Father</v-col>
      <v-col cols="8">
        <span v-if="isPopup">{{ fatherInfo.sample_name }}</span>
        <SampleInfo v-else :projectId="projectId" :cohortId="cohortId" :sampleName="fatherInfo.sample_name"></SampleInfo>

        <!-- <span v-else>{{ fatherInfo.sample_name }}</span> -->
        - <span :style="'color:' + getDiseaseColor(fatherInfo.status)"> {{ fatherInfo.status }}</span>
      </v-col>
    </v-row>
    <v-row dense class="grey lighten-5 px-4">
      <v-col cols="4" class="font-weight-medium">Mother</v-col>
      <v-col cols="8">
        <span v-if="isPopup">{{ motherInfo.sample_name }}</span>
        <SampleInfo v-else :projectId="projectId" :cohortId="cohortId" :sampleName="motherInfo.sample_name"></SampleInfo>
        - <span :style="'color:' + getDiseaseColor(motherInfo.status)"> {{ motherInfo.status }}</span></v-col
      >
    </v-row>
    <v-row dense class="px-4">
      <v-col cols="4" class="font-weight-medium">Family</v-col>
      <v-col cols="8">{{ sampleInfo.family_id }} </v-col>
    </v-row>
    <v-row dense class="grey lighten-5 px-4">
      <v-col cols="4" class="font-weight-medium">Analysis</v-col>
      <v-col cols="8" class="d-flex flex-row">
        <div>
          {{ patient_analysis?.hr_status?.msg }} 
          <span v-if="patient_analysis?.decision?.title">
          : {{ patient_analysis?.decision?.title }}

          </span>
        </div>
        <div v-if="isPopup" class="ml-auto">
          <v-tooltip :open-delay="300" bottom>
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" @click="openIndividualAnalysis" >
                <v-icon small class="mb-1" color="primary">mdi-open-in-new</v-icon>
              </a>
            </template>
            <span>View {{ sampleInfo?.sample_name }} in Individual Analysis</span>
          </v-tooltip>

        </div>
      </v-col>
    </v-row>
  </div>
</template>
 <script>
import { getSamples } from "@/api/sample.js";

export default {
  name: "PatientInfo",
  components: { SampleInfo: () => import("@/components/Common/SampleInfo.vue") },
  props: {
    sampleInfo: {
      type: Object,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  data:()=>({
    patient_analysis : null,
    sampleStatues: {
                DONE: {
                  name: "DONE",
                  color: "#689879",
                  icon: "mdi-check",
                  msg: "Done",
                },
                IN_PROGRESS: {
                  name: "IN_PROGRESS",
                  color: "#e9a643",
                  icon: "mdi-progress-check ",
                  msg: "In Progress",
                },
                NOT_ANALYZED: {
                  name: "NOT_ANALYZED",
                  color: "#93a4de",
                  icon: " mdi-creation-outline",
                  msg: "Not Analyzed",
                },
              },
  }),
  methods: {
    getDiseaseColor(status) {
      if (status == "affected") {
        return "red";
      } else {
        return "grey";
      }
    },
    loadAllSamples() {
      if (this.sampleInfo) {
        const _this = this;
        getSamples(this.cohortId, this.projectId, (res) => {
          _this.patient_analysis = res.find((item) => item.sample_name == _this.sampleInfo.sample_name)?.status;
          if(_this.patient_analysis?.status){
            _this.patient_analysis.hr_status = _this.sampleStatues[_this.patient_analysis.status]
          }
          // console.log(_this.patient_analysis);
        });
      }
    },
    openIndividualAnalysis(){
      window.open(this.sampleOnIndividualAnalysisHref, "_blank").focus();

    }
  },
  created() {
    this.loadAllSamples(); //TO get decision and Analysis Status
  },

  computed: {
    fatherInfo() {
      return {
        sample_name: this.sampleInfo.father,
        status: this.sampleInfo.father_status?.name || null,
        color: this.getDiseaseColor(this.sampleInfo.father_status?.name || null),
        href: this.sampleInfo.fatherId != null ? this.$router.resolve({ name: "LucidAllAnalysisSampleVariants", params: { id: this.projectId, cohortid: this.cohortId, sampleid: this.sampleInfo.fatherId } }).href : null,
      };
    },
    motherInfo() {
      return {
        sample_name: this.sampleInfo?.mother,
        status: this.sampleInfo.mother_status?.name || null,
        color: this.getDiseaseColor(this.sampleInfo.mother_status?.name || null),
        href: this.sampleInfo.motherId != null ? this.$router.resolve({ name: "LucidAllAnalysisSampleVariants", params: { id: this.projectId, cohortid: this.cohortId, sampleid: this.sampleInfo.motherId } }).href : null,
      };
    },
    sampleOnIndividualAnalysisHref(){
        let href= null
        if(this.sampleInfo){
            href= this.$router.resolve({ name: 'LucidAllAnalysisSampleVariants', params: { id: this.projectId, cohortid: this.cohortId, sampleid: this.sampleInfo?.id } }).href;
        }
        return href

    }
  },
};
</script>