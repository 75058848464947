 <template>
  <div class="body-2">
    <div class="d-flex flex-wrap">
      <div class="mr-2 font-weight-medium">Inheritance:</div>
      <div v-for="i in sampleInfo?.disease_inheritance || []" :key="i">
        <v-chip small class="mr-2 mb-2 caption font-weight-medium grey lighten-3" label>{{ i }}</v-chip>
      </div>
    </div>
    <div class="d-flex flex-wrap">
      <div class="mr-2 font-weight-medium">Phenotypes and Terms:</div>
      <div v-for="(term, index) in sampleInfo.hpo_terms.slice(0, 8)" :key="index">
        <v-chip small class="mr-2 mb-2 caption primary--text accent" label>{{ term }}</v-chip>
      </div>
      <v-tooltip v-if="sampleInfo.hpo_terms.length > 8" bottom :open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-on="on" v-bind="attrs" class="mr-2 mb-2 text-body-2 primary--text accent" label>...</v-chip>
        </template>
        <div class="d-flex flex-wrap py-2" style="max-width: 800px">
          <div v-for="(term, index) in sampleInfo.hpo_terms.slice(8)" :key="index">
            <v-chip small class="mr-2 mb-2 text-body-2 primary--text accent" label>{{ term }}</v-chip>
          </div>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>
 <script>
export default {
  name: "PatientInfo",
  props: {
    sampleInfo: {
      type: Object,
      required: true,
    }
  },

};
</script>