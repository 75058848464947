<template>
  <div>
    <LegendItem :clickable="clickable" v-for="(l, idx) in legendItems" :key="`xx-${idx}`" :x="l.x" :y="l.y" :text="l.text" :fillColor="l.fillColor" @onClick="onClick" :borderColor="l.borderColor" :borderStyle="l.borderStyle" :type="l.type"></LegendItem>
  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";

Vue.use(VueKonva);
import LegendItem from "@/components/defaultAnalysis/tad/LegendItem.vue";
import { getTextSizeInPixel } from "@/utils/textUtils.js";

export default {
  name: "Legend",
  components: { LegendItem },
  props: {
    items: {
      type: Array,
      required: true,
    },
    x: {
      type: Number,
      required: true,
    },
    y: {
      type: Number,
      required: true,
    },
    eventIdentifier:{
      type: String,
      required: true,
    },
    clickable:{
      type: Boolean,
      default: false
    }

  },
  methods:{
    onClick(text, show){
      this.$eventBus.$emit(`on${this.eventIdentifier}LegendItemClicked`, text, show )
    }
  },
  computed: {
    legendItems() {
        let leftMargin=0
      return this.items.map((item, idx) => {
        let r = item;
        if(idx > 0){
            leftMargin +=  getTextSizeInPixel(this, this.items[idx - 1].text, "normal 12px Roboto")
            leftMargin += ( this.items[idx - 1].type === 'line' ? 28 : 3)
        }
        r["x"] = this.x + leftMargin;
        r["y"] = this.y;
        r['fillColor'] = item?.fillColor || null
        r['borderColor'] = item?.borderColor || null
        r['borderStyle'] = item?.borderStyle || null
        r['type'] = item?.type || 'square'
        return r;
      });
    },
  },
};
</script>