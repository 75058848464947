<template>
  <v-menu v-if="compact" right v-model="menu" open-on-click offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-if="note" v-on="on" v-bind="attrs" color="primary" class="mb-1">mdi-comment-eye-outline</v-icon>
      <v-icon v-else v-on="on" v-bind="attrs" color="primary" class="mb-1">mdi-comment-outline</v-icon>
    </template>

    <v-card width="600px" max-height="">
      <v-card-title class="grey lighten-2 d-flex flex-row pt-1 pb-1">
        Comment
        <v-btn icon class="ml-auto" color="grey" @click="menu = false"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="d-flex flex-column">
        <v-textarea class="mt-4" outlined name="input-7-1" v-model="comment" hide-details=""></v-textarea>
        <v-btn class="ml-auto mt-2" color="primary" :disabled="!comment" @click="onSave()">Save</v-btn>
      </v-card-text>
    </v-card>
  </v-menu>
  <div v-else>
    <div class="d-flex flex-row ">
      <div class="body-1 mb-2 font-weight-medium grey--text text--darken-1 " >
      Your Comment:
    </div>
      <div class="ml-auto">
        <v-btn icon color="primary" small  :disabled="isEditing" @click="isEditing = true"> <v-icon >mdi-note-edit-outline</v-icon> </v-btn>
        <v-btn icon color="primary" small :disabled="!isEditing" @click="onSave()"><v-icon  >mdi-content-save-outline</v-icon></v-btn>
      </div>
    </div>
    <v-textarea  v-if="isEditing" class="body-2 ml-2" height="150px" outlined name="input-7-1" v-model="comment" hide-details=""></v-textarea>
    <div v-else class="body-2 ml-4"> {{ comment }}</div>
  </div>
</template>
<script>
import { updateVariantAnnotation } from "@/api/user/dataset";

export default {
  name: "LucidAllAnalysisNote",
  props: {
    note: {
      type: String,
      default: null,
    },
    variantId: {
      type: String,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    databaseId: {
      type: Number,
      required: true,
    },
    compact: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    comment: null,
    menu: false,
    isEditing: false
  }),
  created() {
    this.comment = this.note;
  },
  methods: {
    onCancel() {
      this.menu = false;
    },
    onSave() {
      const payload = {
        note: this.comment,
      };
      const _this = this;
      updateVariantAnnotation(
        this.cohortId,
        this.databaseId,
        this.variantId,
        payload,
        () => {
          _this.menu = false;
        _this.isEditing = false

        },
        "Comment saved successfully "
      );
    },
  },
};
</script>
 
