 <template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Variant</v-tab>
      <v-tab>Proband</v-tab>
      <v-tab>Genes</v-tab>
      <v-tab>Regolume</v-tab>
      <v-tab>Notes</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div v-if="variantInfo" class="variantParamBox d-flex flex-row mx-4 mt-2">
          <div style="width: 250px" class="pl-2 py-3 grey--text text--darken-2 font-weight-medium">Label</div>
          <div class="pl-2 py-2 text-wrap font-weight-medium" :style="`color: ${variantLabelColors[variantInfo?.__usr?.label?.id]}`">
            {{ variantInfo?.__usr?.label?.title }}
          </div>
        </div>
        <div v-if="variantInfo" class="d-flex flex-wrap body-2 mx-4">
          <div v-for="item in Object.entries(variantInfo).filter((x) => x[0] != 'ID' && x[0] != '__usr' && x[0] != '__organization')" :key="item[0]" class="variantParamBox d-flex flex-row mr-6">
            <div style="width: 250px" class="pl-2 py-3 grey--text text--darken-1 font-weight-medium">{{ item[0] }}</div>
            <div class="pl-2 py-2 text-wrap">
              {{ Array.isArray(item[1]) ? item[1].join(", ") : item[1] }}
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <IndividualAnalysisPatientInfo class="mt-6 mx-4" v-if="sampleInfo" :projectId="projectId" :cohortId="cohortId" :sampleInfo="sampleInfo"></IndividualAnalysisPatientInfo>
      </v-tab-item>
      <v-tab-item>
        <v-card v-for="gene in variantInfo?.OVERLAPPING_GENES || []" :key="`tab-${gene}`" flat :max-width="width" class="ml-4 mt-6 mb-4 infoBox">
          <v-card-title class="grey lighten-4 font-weight-medium pt-1" style="height: 40px !important">
            <div>{{ gene }}</div>
          </v-card-title>
          <v-card-text class="px-1">
            <GeneInfo :compact="false" :id="gene"></GeneInfo>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <div v-if="variantInfo" style="position: relative" :style="`width:${width};`" class="ml-4 mt-6">
          <TadCoolbox
            class="stickyCoolbox"
            ref="tadCoolbox"
            v-if="variantType == 'SV'"
            :isDialog="false"
            :cohortId="cohortId"
            :projectId="projectId"
            :selectedDatabaseId="mDatasetId"
            :variantId="variantInfo['ID']"
            :sv="[{ id: variantInfo['ID'], start: variantInfo['START'], end: variantInfo['END'], type: variantInfo['TYPE'], size: variantInfo['SIZE'], info: null }]"
            :selectedUsersGenes="[]"
            :chromosome="{ id: variantInfo?.CHR || null, start: 0, end: 0 }"
            :differentiallyExpressedGenes="variantInfo['DEGS'] || []"
          ></TadCoolbox>
          <TrSnvCoolbox
            v-if="variantType == 'SNV'"
            ref="tadCoolbox"
            :type="variantType"
            rarePathogenicTrackHintCondition="( GNOMADG_AF ≤ 0.01, QUAL ≥ 30, GT ≠ 0/0 ) "
            :cohortId="cohortId"
            :projectId="projectId"
            :selectedDatabaseId="mDatasetId"
            :variantId="variantInfo['ID']"
            :sv="[{ id: variantInfo['ID'], start: variantInfo['START'], end: variantInfo['END'], type: variantInfo['TYPE'], size: variantInfo['SIZE'], info: null }]"
            :chromosome="{ id: variantInfo?.CHR || null, start: 0, end: 0 }"
            :selectedUsersGenes="[]"
            :differentiallyExpressedGenes="variantInfo['DEGS'] || []"
          ></TrSnvCoolbox>
          <TrSnvCoolbox
            v-if="variantType == 'TR'"
            ref="tadCoolbox"
            :type="variantType"
            rarePathogenicTrackHintCondition="( QUAL ≥ 20, GT ≠ 0/0, FILTER=PASS, MAX_POP_DIV ≥ 2 )  "
            :cohortId="cohortId"
            :projectId="projectId"
            :selectedDatabaseId="mDatasetId"
            :variantId="variantInfo['ID']"
            :sv="[
              {
                id: variantInfo['ID'],
                start: variantInfo['START'],
                end: variantInfo['END'],
                type: variantInfo['TYPE'],
                size: variantInfo['SIZE'],
                repcn: variantInfo['REPCN'],
                ref_cn: variantInfo['REF_CN'],
                rep_unit: variantInfo['REP_UNIT'],
                info: null,
              },
            ]"
            :chromosome="{ id: variantInfo?.CHR || null, start: 0, end: 0 }"
            :selectedUsersGenes="[]"
            :differentiallyExpressedGenes="variantInfo['DEGS'] || []"
          ></TrSnvCoolbox>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div :style="`max-width: ${width};`" class="pl-4">
          <LucidAllAnalysisOrganization
            v-if="variantInfo && variantInfo.__organization !== undefined && variantInfo.__organization.length > 0"
            :organization="variantInfo.__organization"
            :compact="false"
            :variantLabelColors="variantLabelColors"
          ></LucidAllAnalysisOrganization>
          <v-divider v-if="variantInfo && variantInfo.__organization !== undefined && variantInfo.__organization.length > 0"></v-divider>
          <LucidAllAnalysisNote class="mt-4" :compact="false" v-if="variantInfo" :variantId="variantId" :cohortId="cohortId" :databaseId="mDatasetId" :note="variantInfo.__usr?.note || null"></LucidAllAnalysisNote>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
 <script>
import { getDatasetsByProject } from "@/api/dataset.js";
import { getData } from "@/api/user/dataset";
// import { getFieldGroups } from "@/api/settings";
import { getDatasetFields } from "@/api/dataset";
import { getSampleByName } from "@/api/sample.js";
import IndividualAnalysisPatientInfo from "@/components/IndividualAnalysis/IndividualAnalysisPatientInfo.vue";
import GeneInfo from "@/components/defaultAnalysis/GeneInfo.vue";
// import TadCoolbox from "@/components/defaultAnalysis/tad/TadCoolbox.vue";
// import TrSnvCoolbox from "@/components/defaultAnalysis/tad/TrSnvCoolbox.vue";
import LucidAllAnalysisNote from "@/components/LucidAllAnalysis/LucidAllAnalysisNote.vue";
import LucidAllAnalysisOrganization from "@/components/LucidAllAnalysis/LucidAllAnalysisOrganization.vue";

export default {
  name: "VariantInfo",
  components: {
    IndividualAnalysisPatientInfo,
    GeneInfo,
    TadCoolbox: () => import("@/components/defaultAnalysis/tad/TadCoolbox.vue"),
    TrSnvCoolbox: () => import("@/components/defaultAnalysis/tad/TrSnvCoolbox.vue"),
    LucidAllAnalysisNote,
    LucidAllAnalysisOrganization,
  },
  props: {
    variantId: {
      type: String,
      required: true,
    },
    datasetId: {
      type: Number,
      required: false,
    },
    variantType: {
      type: String,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    sampleId: {
      type: Number,
      required: false,
    },
  },
  data: () => ({

    width: "1200px",
    tab: null,
    mDatasetId: null,
    variantInfo: null,
    sampleName: null,
    sampleInfo: null,
    variantLabelColors: {
      1: "#00CC88", //KEEP
      2: "#FF0000", //DISCARD
      3: "#615D69", //DON'T KNOW
      4: "orange", //True with unclear significance
    },
  }),
  created() {
    if (this.datasetId == undefined) {
      const _this = this;
      getDatasetsByProject(this.projectId, (res) => {
        _this.mDatasetId = res.find((item) => item.datasetType.name == this.variantType).id;
        console.log('FETCH',this.variantType,this.variantId,_this.mDatasetId)
        _this.fetchVariant();
      });
    } else {
      this.mDatasetId = this.datasetId;
      console.log('HAS',this.variantType,this.variantId,this.mDatasetId)
      this.fetchVariant();
    }
  },
  watch: {
    sampleName() {
      if (this.sampleName != null) {
        const _this = this;
        getSampleByName(this.cohortId, this.projectId, this.sampleName, (res) => {
          _this.sampleInfo = res[0];
        });
      }
    },
  },
  methods: {
    fetchVariant() {
      const _this = this;
      getDatasetFields(this.mDatasetId, (rs) => {
        let fields = rs.filter((f) => f.groups.findIndex((g) => g.groupType == "VIEW") >= 0).map((item) => item.title);
        fields.push("SAMPLE");
        fields.push("ID");
        const idField = rs.find((item) => item.title == "ID").name;
        let idFilter = {};
        idFilter[idField] = _this.variantId;
        const exactFilters = [idFilter];
        getData(_this.cohortId, _this.mDatasetId, exactFilters, [], [], [], [], "one_of_all", 1, 0, [], [], [], fields, [], function (res) {
          if (res.result.length > 0) {
            _this.variantInfo = res.result[0];
            _this.sampleName = _this.variantInfo["SAMPLE"];
          }
        });
      });
    },
  },
};
</script>
<style scoped>
.variantParamBox {
  width: 650px;
  border-bottom: 1px #f0f0f0 solid;
}
</style>