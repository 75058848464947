 <template>
  <div ref="variantTabs" style="width: 100%" class="">
    <div class="boxShadow">
      <v-tabs v-model="tab">
        <v-tab>OMIM Disease Inheritance</v-tab>
        <v-tab>Info</v-tab>
        <v-tab>Description</v-tab>
        <v-tab>Samples</v-tab>
        <v-tab>Note</v-tab>
        <v-tab>Variant Filters</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" class="body-2">
        <v-tab-item eager class="pa-4">
          <OmimInheritanceList :compact="false" :info="gene.info?.OMIM || []"></OmimInheritanceList>
          <div v-if="!gene.info.OMIM || (gene.info?.OMIM?.length || []) == 0" class="caption grey--text">No data is available</div>
        </v-tab-item>
        <v-tab-item eager class="pa-4">
          <div class="d-flex flex-row flex-wrap">
            <div v-for="item in baseInfo" :key="item.name" style="width: 250px" class="pa-1">
              <span class="grey--text text--darken-1">{{ item.name }}: </span><span>{{ item.value }}</span>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item eager class="pa-4">
          <GeneInfo :id="gene.name" :compact="false"></GeneInfo>
        </v-tab-item>
        <v-tab-item eager class="pa-4">
          <div class="d-flex flex-row flex-wrap">
            <div v-for="item in sampleInfo" :key="item.name" style="width: 300px" class="pa-1">
              <span class="grey--text text--darken-1">{{ item.name }}: </span><span>{{ item.value }}</span>
            </div>
          </div>
          <div class="mt-1 ml-2">
            <span class="grey--text text--darken-1">Samples:</span>
          <SampleInfo v-for="sample in sampleList" :key="sample" :projectId="projectId" :cohortId="cohortId" :sampleName="sample"></SampleInfo>

            <!-- <span v-for="sample in sampleList" :key="sample" class="mx-1 primary--text text-decoration-underline sampleHover" style="text-decoration-style: dotted !important" @click.stop="onSampleClick(sample)">
              {{ sample }}
              <v-icon class="mr-2" color="primary" x-small>mdi-open-in-new</v-icon>
            </span> -->
          </div>
        </v-tab-item>
        <v-tab-item eager class="pa-4">
          <v-row>
            <v-col>
              <CohortAnalysisGeneNote :compact="false" :gene="gene.name" :cohortId="cohortId" :projectId="projectId" :note="gene.__usr_label?.note || null"></CohortAnalysisGeneNote>
            </v-col>
            <v-col>
              <OrganizationComments :compact="false" v-if="gene.ORGANIZATION.length > 0" :comments="gene.ORGANIZATION"></OrganizationComments>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item eager class="pa-4">
          <div></div>
          <DefaultAnalysisFilters
            v-if="showFilters"
            :selectedDatabases="[datasets.SV.info, datasets.SNV.info, datasets.TR.info]"
            :cohortId="cohortId"
            searchButtonTitle="Apply"
            :saveFilters="false"
            :showVariantLabelFilter="false"
            :reversibleCategorical="true"
            :defaultFilters="defaultFilters"
            :defaultFiltersLogic="filterLogic"
            @onFilterSet="onFilterSet"
          >
            <template v-slot:extraFilters>
              <div class="d-flex flex-row grey lighten-3 pl-6">
                <div class="mt-2 mr-2 grey--text text--darken-1 font-weight-bold">Regions:</div>
                <v-checkbox class="mr-6" dense v-model="extraFilters.codingVariant" label="Coding Varaints"></v-checkbox>
                <v-checkbox class="mr-6" dense v-model="extraFilters.tadSvs" label="TAD SVs"></v-checkbox>
                <v-checkbox disabled class="mr-6" dense v-model="extraFilters.tadSnvs" label="TAD SNVs"></v-checkbox>
              </div>
            </template>
          </DefaultAnalysisFilters>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <div class="d-flex flex-row">
      <div v-if="showCoolbox" style="position: relative" :style="'width: ' + coolboxFrameWidth">
        <CohortCoolbox
          ref="tadCoolbox"
          class="boxShadow"
          :regulomeCollapsed="regulomeCollapsed"
          :cohortId="cohortId"
          :projectId="projectId"
          :gene="gene.name"
          :chromosome="{ id: gene.chr, start: 0, end: 0 }"
          :samples="[]"
          :selectedUsersGenes="[]"
          :filters="localFilters.filters"
          @onMinMax="onMinMax"
          @onRegulomeVisibilityChanged="onRegulomeVisibilityChanged"
        ></CohortCoolbox>
        <div class="white boxShadow px-5 pt-1 mt-4">
          <ViewFilteredVariants class="mt-4" v-if="showVariants" ref="ViewFilteredVariants" title="Variants" :filters="localFilters" :cohortId="cohortId" :projectId="projectId"></ViewFilteredVariants>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
import DefaultAnalysisFilters from "@/components/defaultAnalysis/DefaultAnalysisFilters.vue";
import OmimInheritanceList from "@/components/CohorAnalysis/OmimInheritanceList.vue";
import GeneInfo from "@/components/defaultAnalysis/GeneInfo.vue";
import CohortAnalysisGeneNote from "@/components/CohorAnalysis/CohortAnalysisGeneNote.vue";
import CohortCoolbox from "@/components/defaultAnalysis/tad/CohortCoolbox.vue";
import OrganizationComments from "@/components/OrganizationComments.vue";
import ViewFilteredVariants from "@/components/defaultAnalysis/ViewFilteredVariants.vue";
import {getSampleByName } from "@/api/sample.js"
import { pruneNumber } from "@/utils/number.js";
export default {
  name: "CohortAnalysisGeneDetails",
  components: { OmimInheritanceList, GeneInfo, CohortAnalysisGeneNote, OrganizationComments, CohortCoolbox, DefaultAnalysisFilters, ViewFilteredVariants, SampleInfo: () => import("@/components/Common/SampleInfo.vue") },
  props: {
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    filterLabels: {
      type: Array,
      required: true,
    },
    filterLogic:{
      type: Object,
      required: true,
    },
    gene: {
      type: Object,
      required: true,
    },
    datasets: {
      type: Object,
      required: true,
    },
    nonCodingBurdenTest:{
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    tab: null,
    variantTab: null,
    showCoolbox: false,
    showFilters: false,
    showVariants: false,
    coolboxFrameWidth: "1000px",
    extraFilters: {
      codingVariant: true,
      tadSvs: true,
      tadSnvs: false,
    },
    selectedReigion: ["CODING_VARIANTS", "TAD_SVS"],
    menu: null,
    minMax: {
      mn: 0,
      mx: 0,
    },
    regulomeCollapsed: false,
    localFilters: null,
  }),
  methods: {
    onRegulomeVisibilityChanged(val) {
      this.regulomeCollapsed = val;
    },
    onMinMax(val) {
      this.minMax = val;
      this.$nextTick(() => {
        this.showFilters = true;
      });
    },
    // onFilterSet(filters, variantLabels, selectedPropertiesLabels, collabLabelsPerDs) {
    onFilterSet(filters) {
      let f = {
        SV:{},
        SNV:{},
        TR:{}
      }
      Object.keys(this.filters).forEach((dsType) => {
        f[dsType].exactFilters = filters[dsType].exactFilters;
        f[dsType].inFilters = filters[dsType].inFilters;
        f[dsType].notFilters = filters[dsType].notFilters;
        f[dsType].rangeFilters = [...filters[dsType].rangeFromfilters, ...filters[dsType].rangeTofilters, ...filters[dsType].rangeBetweenfilters];
      });
      this.updateLocalFilters(f, true)
      this.redraw(false);
    },
    onSampleClick(sample) {
      const _this = this
      getSampleByName(this.cohortId, this.projectId, sample, (res) => {
        const routeData = _this.$router.resolve({ name: "LucidAllAnalysisSampleVariants", params: { id: this.projectId, cohortid: this.cohortId, sampleid: res[0].id } });
        window.open(routeData.href, "_blank").focus();
      })

    },
    loadVariants() {
      this.showVariants = false;
      // console.log('localFilters',this.localFilters)
      this.$nextTick(() => {
        this.showVariants = true;
      });
    },
    updateLocalFilters(nfilters, manual = false){
      let filters = JSON.parse(JSON.stringify(nfilters))
      if(!manual){
        ['SV','SNV', 'TR'].forEach(ds => {
          const chrField = this.datasets[ds].fields.find((item) => item.title == "CHR").name;
          let chrFilter = {};
          chrFilter[chrField] = this.gene.chr;
          filters[ds].exactFilters.push(chrFilter)
          
          // const startField = this.datasets.SV.fields.find((item) => item.title == "START").name;
          // let startFilter = {};
          // startFilter[startField] = { lte: this.minMax.mx.toString() }
          // filters[ds].rangeFilters.push(startFilter)
          
          // const endField = this.datasets.SV.fields.find((item) => item.title == "END").name;
          // let endFilter = {};
          // endFilter[endField] = { gte: this.minMax.mn.toString() }
          // filters[ds].rangeFilters.push(endFilter)
        })
      }

      const f_tr = this.datasets.TR.fields.find((item) => item.title == "OVERLAPPING_GENES")?.name;
      if (f_tr) {
        // console.log(`ADD OVERLAPPING GENES to SVS ${f}:${this.gene.name}`);
        const fl = {};
        fl[f_tr] = [this.gene.name];
        filters.TR.inFilters.push(fl);
      }

      if (this.extraFilters.tadSvs) {
        const f = this.datasets.SV.fields.find((item) => item.title == "TADS")?.name;
        if (f) {
          // console.log(`ADD TAD to SVS ${f}:${this.gene.info.TADS}`);
          const fl = {};
          fl[f] = this.gene.info.TADS;
          filters.SV.inFilters.push(fl);
        }
      } else {
        if (this.extraFilters.codingVariant) {
          const f = this.datasets.SV.fields.find((item) => item.title == "OVERLAPPING_GENES")?.name;
          if (f) {
            // console.log(`ADD OVERLAPPING GENES to SVS ${f}:${this.gene.name}`);
            const fl = {};
            fl[f] = [this.gene.name];
            filters.SV.inFilters.push(fl);
          }
        }
      }
      if (this.extraFilters.tadSnvs) {
        const f = this.datasets.SNV.fields.find((item) => item.title == "TADS")?.name;
        if (f) {
          // console.log(`ADD TAD to SNVS ${f}:${this.gene.info.TADS}`);
          const fl = {};
          fl[f] = this.gene.info.TADS;
          filters.SNV.inFilters.push(fl);
        }
      } else {
        if (this.extraFilters.codingVariant) {
          const f = this.datasets.SNV.fields.find((item) => item.title == "OVERLAPPING_GENES")?.name;
          if (f) {
            // console.log(`ADD OVERLAPPING GENES to SNVS ${f}:`,this.gene.name);
            const fl = {};
            fl[f] = [this.gene.name];
            filters.SNV.inFilters.push(fl);
          }
        }
      }

      this.localFilters = {
          filters: filters,
          variantLabels: { SV: [], SNV: [], TR: [] },
          collabLabelsPerDs: { SV: [], SNV: [], TR:[] },
      };
      // console.log('SV:',JSON.stringify(this.localFilters.filters.SV))
      // console.log('SNV:',JSON.stringify(this.localFilters.filters.SNV))

    },
    redraw(redrawFilters = true) {
      this.showCoolbox = false;
      this.showFilters = !redrawFilters;

      this.$nextTick(() => {
        this.coolboxFrameWidth = this.$refs.variantTabs ? String(this.$refs.variantTabs.clientWidth) + "px" : "500px";
        // console.log('REDRAW:',this.coolboxFrameWidth, this.$refs.variantTabs.clientWidth)
        this.showCoolbox = true;

        // this.localFilters = {
        //   filters: this.filters,
        //   variantLabels: { SV: [], SNV: [] },
        //   collabLabelsPerDs: { SV: [], SNV: [] },
        // };
        this.loadVariants();
      });
    },
  },
  created() {
    // console.log('CREATED:',this.filters)
    this.extraFilters.tadSvs = this.nonCodingBurdenTest
    this.updateLocalFilters(this.filters)
    this.redraw();
  },
  watch: {
    gene() {
      // console.log('WATCH:',this.filters)
      this.updateLocalFilters(this.filters)
      this.redraw();
    },
  },
  computed: {
    defaultFilters() {
      const res = {};
      this.filterLabels.forEach((element) => {
        res[element.id] = element.rawValue;
      });
      res["CHR"] = this.gene.chr;
      res["START"] = [null, this.minMax.mx.toString()];
      res["END"] = [this.minMax.mn.toString(), null];
      return res;
    },
    baseInfo() {
      let x = [];
      x.push({ name: "Chromosome", value: this.gene.chr });
      x.push({ name: "Start", value: this.gene.start });
      x.push({ name: "End", value: this.gene.end });
      x.push({ name: "LOEUF", value: pruneNumber(this.gene?.info?.SCORES?.LOEUF || null, 4) });
      x.push({ name: "Triploinsufficiency", value: pruneNumber(this.gene?.info?.SCORES?.Triploinsufficiency || null, 4) });
      x.push({ name: "pLI", value: pruneNumber(this.gene?.info?.SCORES?.pLI || null, 4) });
      x.push({ name: "Haploinsufficiency", value: pruneNumber(this.gene?.info?.SCORES?.Haploinsufficiency || null, 4) });
      x.push({ name: "Score Scatter Variant", value: pruneNumber(this.gene.SCORE_SCATTER_VARIANT, 4) });
      x.push({ name: "Syn. Z-Score", value: this.gene?.info?.SCORES["syn.z_score"] || null });
      x.push({ name: "Mis. Z-Score", value: this.gene?.info?.SCORES["mis.z_score"] || null });
      return x;
    },
    sampleInfo() {
      let x = [];
      x.push({ name: "#Samples with SV Hits", value: this.gene.SV_SAMPLES.length });
      x.push({ name: "#Samples with SNV Hits", value: this.gene.SNV_SAMPLES.length });
      x.push({ name: "#Samples with Hits in Coding", value: this.gene.NO_SAMPLES_HIT_IN_GENE_CODING });
      x.push({ name: "#Samples with Hits in Body", value: this.gene.NO_OF_UNIQ_SAMPLES });
      return x;
    },
    sampleList() {
      return Array.from(new Set(this.gene.SV_SAMPLES.concat(this.gene.SNV_SAMPLES)));
    },
  },
};
</script>
<style lang="scss" scoped>
.sampleHover:hover {
  cursor: pointer;
}
.boxShadow {
  box-shadow: 0px 4px 10px rgba(217, 217, 217, 0.2);
}
</style>