<template>
  <v-menu v-model="menu" left open-on-hover offset-x :open-delay="300">
    <template v-slot:activator="{ on, attrs }">
      <span class="primary--text sampleText text-decoration-underline dottecUnderline" v-on="on" v-bind="attrs">{{ sampleName }} {{ sampleInfo?.sampleName }}</span>
    </template>
    <div v-if="sampleInfo" class="pa-2 white d-flex flex-column" style="width: 500px">
      <PatientInfo :isPopup="true"  :projectId="projectId" :cohortId="cohortId" class="ma-3" :sampleInfo="sampleInfo"></PatientInfo>

      <PatientPedigree :sampleInfo="sampleInfo" :cohortId="cohortId" :projectId="projectId"></PatientPedigree>
      <PatientDiseaseInfo  class="mx-6 my-2" :sampleInfo="sampleInfo"></PatientDiseaseInfo>
      <!-- <router-link :to="">dimdim</router-link> -->
    </div>
  </v-menu>
</template>
<script>
import { getSample, getSampleByName } from "@/api/sample.js";
import PatientPedigree from "@/components/Common/PatientPedigree.vue";
import PatientInfo from "@/components/Common/PatientInfo.vue";
import PatientDiseaseInfo from "@/components/Common/PatientDiseaseInfo.vue";
export default {
  name: "SampleInfo",
  components: { PatientInfo, PatientDiseaseInfo, PatientPedigree },
  props: {
    sampleId: {
      type: Number,
      required: false,
    },
    sampleName: {
      type: String,
      required: false,
    },
    projectId: {
      type: Number,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    sampleInfo: null,
    menu: false,

  }),
  created() {},
  watch: {
    menu() {
      if (this.menu) {
        this.load_sample_info();
      }
    },
  },

  methods: {
    load_sample_info() {
      const _this = this;
      if (this.sampleId !== null && this.sampleId !== undefined) {
        getSample(this.cohortId, this.projectId, this.sampleId, (res) => {
          _this.sampleInfo = res;
        });
      } else if (this.sampleName !== null && this.sampleName !== undefined) {
        getSampleByName(this.cohortId, this.projectId, this.sampleName, (res) => {
          _this.sampleInfo = res[0];
        });
      } else {
        throw new Error("Either sampleName ot sampleId should be provided to SampleInfo component ");
      }
    },
  }
};
</script>
<style scoped>
.dottecUnderline {
  text-decoration-style: dotted !important;
}
.sampleText:hover {
  cursor: help;
}
</style>